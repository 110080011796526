<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-50">
        <edit-profile-form
          header-color="green"
          :user="currentUser"
          @updateProfile="onUpdateProfile"
        ></edit-profile-form>
      </div>
      <!--
      <div class="md-layout-item md-medium-size-100 md-size-33">
        <change-password-form
          header-color="green"
          @changePassword="onChangePassword"
        ></change-password-form>
      </div>
      -->
      <div class="md-layout-item md-medium-size-100 md-size-50">
        <user-card :user="currentUser"></user-card>
      </div>
    </div>
  </div>
</template>

<script>
import EditProfileForm from '@/components/Avalia/UserProfile/EditProfileForm.vue';
// import ChangePasswordForm from '@/components/Avalia/UserProfile/ChangePasswordForm.vue';
import UserCard from '@/components/Avalia/UserProfile/UserCard.vue';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';

export default {
  components: {
    EditProfileForm,
    // ChangePasswordForm,
    UserCard,
  },
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER']
        ? this.$store.getters['users/GET_CURRENT_USER']
        : {
          displayName: '',
          firstName: '',
          lastName: '',
          sub: '',
        }; // Needed to avoid error in render on user watcher when logging out from UserProfile page
    },
  },
  methods: {
    onUpdateProfile(userProfile) {
      api.idm
        .patch('/api/public/users/@me', {
          displayName: userProfile.displayName,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
        })
        .then((response) => {
          this.$store.dispatch('users/updateToken', response.data.token);
          const successMessage = 'Profile successfully Updated';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          this.$notify({
            message: 'Profile update failed',
            icon: 'add_alert',
            type: 'warning',
            timeout: 3500,
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
        });
    },
    onChangePassword(passwords) {
      api.idm
        .patch('/api/public/users/@me', {
          oldPassword: passwords.password,
          newPassword: passwords.newPassword,
        })
        .then((response) => {
          this.$store.dispatch('users/updateToken', response.data.token);
          this.$notify({
            message: 'Password successfully Updated',
            icon: 'add_success',
            type: 'success',
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
        })
        .catch((error) => {
          this.$notify({
            message: 'Password update failed',
            icon: 'add_alert',
            type: 'warning',
            timeout: 3500,
            horizontalAlign: 'right',
            verticalAlign: 'top',
          });
        });
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.text-right {
  display: flex;
}
</style>
