<template>
  <md-card class="md-card-profile custom-card ">
    <md-card-content>
      <h4 class="card-title">
        {{ displayName }}
      </h4>
    </md-card-content>
    <div class="md-card-avatar custom-avatar">
      <gravatar
        :email="currentEmail"
        alt="avatar"
        :size="100"
        default-img="retro"
      />
    </div>
    <md-card-content class="custom-card-content">
      <h4 class="card-title">
        {{ fullName }}
      </h4>
    </md-card-content>
  </md-card>
</template>
<script>
import Gravatar from 'vue-gravatar';

export default {
  name: 'UserCard',
  components: {
    Gravatar,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      currentEmail: null,
      displayName: null,
      fullName: null,
    };
  },
  watch: {
    user() {
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    getColorButton(buttonColor) {
      return `md-${buttonColor}`;
    },
    update() {
      this.currentEmail = this.user.sub;
      this.displayName = `${this.user.displayName}`;
      this.fullName = `${this.user.firstName} ${this.user.lastName}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-avatar {
  position: relative;
  top: 4em;
}
.custom-card-content {
  margin-top: 5em !important;
}
.custom-card {
  min-height: 24.8em;
}
</style>
