<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <md-card class="edit-profile-card">
        <md-card-header class="md-card-header-icon md-card-header-cyan">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <h4 class="title">
            Profile
            <!-- <small>Complete your profile</small> -->
          </h4>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider
                name="displayName"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Display Name</label>
                  <md-input v-model="user.displayName" type="text" required :disabled="disabled"/>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="firstName"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>First Name</label>
                  <md-input v-model="user.firstName" type="text" required :disabled="disabled"/>
                </md-field>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="lastName"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Last Name</label>
                  <md-input v-model="user.lastName" type="text" required :disabled="disabled" />
                </md-field>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Email Address</label>
                  <md-input v-model="user.sub" type="email" disabled />
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <div v-if="!disabled" class="form-category">
              * Required fields
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button v-if="!disabled" class="md-raised md-info mt-4" type="submit">
                Update Profile
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('email', email);
extend('required', required);

export default {
  name: 'EditProfileForm',
  components: {
    SlideYDownTransition,
  },
  props: {
    user: Object,
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onSubmit() {
      this.$emit('updateProfile', this.user);
    },
  },
};
</script>
<style>
.edit-profile-card{
  padding-bottom: 6rem;
}
</style>
